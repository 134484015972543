@import 'styles/tokens/_colors';
@import 'styles/_media';
@import 'styles/_mixins';

.CandidateResumeInfoEmpty {
  display: flex;
  border-bottom: 1px solid $color-light-grey;
  padding-bottom: 44px;

  @include media-up(breakpoint-md) {
    padding-bottom: 32px;
  }
}

.CandidateResumeInfoEmpty__avatar {
  overflow: hidden;
  margin-right: 10px;
  border-radius: 20px;
  width: 60px;
  height: 60px;
  min-width: 60px;
  background-color: $color-bg;

  @include media-up(breakpoint-md) {
    margin-right: 26px;
    width: 194px;
    height: 296px;
    min-width: 194px;

    svg {
      width: 60px;
      height: 60px;
    }
  }

  @include media-up(breakpoint-xl) {
    width: 230px;
    min-width: 230px;
  }
}

.CandidateResumeInfoEmpty__titleWrapper {
  display: flex;
  align-items: center;
}

.CandidateResumeInfoEmpty__title {
  margin-bottom: 20px;
}

.CandidateResumeInfoEmpty__content {
  flex-grow: 1;
}

.CandidateResumeInfoEmpty__emptyBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed $color-grey;
  border-radius: 20px;
  padding: 0 24px;
  height: 244px;
  color: $color-dark-grey;
}

.CandidateResumeInfoEmpty__emptyBlock_mobile {
  margin-top: 16px;
}

.CandidateResumeInfoEmpty__emptyText {
  max-width: 603px;
  text-align: center;
}