@import 'styles/tokens/_colors';
@import 'styles/_media';

.ShareBanner {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: auto auto 38px;
  border-radius: 20px;
  width: 100%;
  max-width: 1240px;
  background-color: #fff;

  @include media-up(breakpoint-md) {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 0;
    height: 406px;
  }
}

.ShareBanner__logo {
  display: none;

  @include media-up(breakpoint-md) {
    display: block;
  }
}

.ShareBanner__content {
  padding: 16px;

  @include media-up(breakpoint-md) {
    padding: 32px 0 32px 36px;
    max-width: 486px;
  }
}

.ShareBanner__title {
  margin-bottom: 12px;
  color: $color-original-blue;

  @include media-up(breakpoint-md) {
    margin-top: 32px;
    margin-bottom: 14px;
  }
}

.ShareBanner__description {
  margin-bottom: 26px;
  color: $color-dark-grey;

  @include media-up(breakpoint-md) {
    margin-bottom: 30px;
  }
}

.ShareBanner__btns {
  display: flex;
  flex-direction: column;

  @include media-up(breakpoint-md) {
    flex-direction: row;
    align-items: center;
  }
}

.ShareBanner__btn {
  width: 100%;

  &:first-of-type {
    margin-bottom: 16px;
  }

  @include media-up(breakpoint-md) {
    width: 160px;

    &:first-of-type {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
}

.ShareBanner__img {
  display: none;

  @include media-up(breakpoint-md) {
    display: block;
    margin-right: -212px;
    padding-bottom: 32px;
  }

  @include media-up(breakpoint-lg) {
    margin-right: -120px;
    padding-bottom: 0;
  }

  @include media-up(breakpoint-xl) {
    margin-right: 0;
    padding: 0;
  }
}