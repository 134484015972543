@import 'styles/tokens/_shadows';
@import 'styles/tokens/_transitions';
@import 'styles/tokens/_colors';
@import 'styles/_media';

.CompanyCandidateCard {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 16px;
  background: #fff;
  transition: box-shadow $transition-main;
  cursor: pointer;

  &:hover {
    box-shadow: $shadow-intense;
  }
}

.CompanyCandidateCard__badge {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 2px 8px;
  width: fit-content;
  color: #fff;
  background: $color-green;
  transform: translateY(-50%);
}

.CompanyCandidateCard__badgeIcon {
  margin-right: 4px;
}

.CompanyCandidateCard__btnWrapper {
  margin-top: auto;
}

.CompanyCandidateCard__btn {
  margin-top: 40px;
  width: 100%;

  @include media-up(breakpoint-md) {
    margin-top: 74px;
  }

  @include media-up(breakpoint-xxl) {
    margin-top: 40px;
  }
}

/* Card Skeleton */

.CompanyCandidateCard__skeleton {
  overflow: hidden;
  border-radius: 20px;
}