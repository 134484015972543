@import './tokens/transitions';

@keyframes use-ripple-animation {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(10);
  }
}

.rippleAnimation {
  animation: use-ripple-animation 0.35s ease-in;
}