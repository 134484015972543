@use 'sass:math';

@import 'styles/_media';
@import 'styles/tokens/_colors';
@import 'styles/tokens/_transitions';

.VideoPlayer__player {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .VideoPlayer__controls {
      opacity: 1;
      transition: opacity $transition-main;
    }
  }
}

.VideoPlayer__controls {
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  padding: 30px 16px 21px;
  width: 100%;
  height: 300px;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(#000, 0), rgba(#000, 0.8));
  opacity: 0;
}

.VideoPlayer__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 200px;
  margin-bottom: 9px;
}

.VideoPlayer__play {
  display: flex;
  column-gap: 6px;
  align-items: center;

  @include media-up(breakpoint-sm) {
    column-gap: 30px;
  }
}

.VideoPlayer__text {
  position: relative;
  text-align: center;
  color: #fff;
}

.VideoPlayer__text_hover {
  width: 32px;

  &:hover {
    color: $color-original-blue;
    cursor: pointer;
  }
}

.VideoPlayer__rate {
  position: absolute;
  left: -10px;
  top: -110px;
  display: none;
  list-style: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(4 3 53 / 5%);
  color: #0c0e75;
  background-color: #fff;
  transition: display $transition-main;

  @include media-up(breakpoint-sm) {
    left: -26px;
    top: -185px;
  }
}

.VideoPlayer__rate_visible {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 8px;
  row-gap: 6px;

  @include media-up(breakpoint-sm) {
    padding: 10px 14px;
    row-gap: 14px;
  }
}

.VideoPlayer__timeline {
  width: 100%;
  height: 100%;
}

.VideoPlayer__icon_small {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  color: #fff;

  &:hover {
    cursor: pointer;

    svg {
      color: $color-original-blue;
    }
  }
}

.VideoPlayer__icon {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 32px;
  height: 32px;
  color: #fff;

  &:hover {
    cursor: pointer;

    p {
      color: $color-original-blue;
    }

    svg {
      color: $color-original-blue;
    }
  }
}

.VideoPlayer__volume {
  display: flex;
  column-gap: 6px;
  align-items: center;
}

.VideoPlayer__spinner {
  display: none;
}

.VideoPlayer__spinner_visible {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;

  svg {
    color: $color-original-blue;
  }
}

$track-color: #fff !default;
$thumb-color: #fff !default;
$thumb-radius: 4px !default;
$thumb-height: 8px !default;
$thumb-width: 8px !default;
$thumb-shadow-size: 2px !default;
$thumb-shadow-blur: 2px !default;
$thumb-shadow-color: rgb(0 0 0 / 20%) !default;
$thumb-border-width: 1px !default;
$thumb-border-color: #fff !default;
$track-width: 56px !default;
$track-height: 3px !default;
$track-shadow-size: 1px !default;
$track-shadow-blur: 1px !default;
$track-shadow-color: rgb(0 0 0 / 20%) !default;
$track-border-width: 0 !default;
$track-border-color: #fff !default;
$track-radius: 0 !default;
$contrast: 5% !default;
$ie-bottom-track-color: darken($track-color, $contrast) !default;

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
  width: $track-width;
  height: $track-height;
  transition: all 0.2s ease;
  cursor: pointer;
}

@mixin thumb {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);

  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  width: $thumb-width;
  height: $thumb-height;
  box-sizing: border-box;
  background: $thumb-color;
  cursor: pointer;
}

.VideoPlayer__volume_range {
  appearance: none;
  margin: math.div($thumb-height, 2) 0;
  width: $track-width;
  background: transparent;

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-runnable-track {
    @include track;
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);

    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
    background: $track-color;
  }

  &::-webkit-slider-thumb {
    @include thumb;

    appearance: none;
    margin-top: (math.div((-$track-border-width * 2 + $track-height), 2) - math.div($thumb-height, 2));
  }

  &::-moz-range-track {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    @include track;

    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
    height: math.div($track-height, 2);
    background: $track-color;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-track {
    @include track;

    border-width: math.div($thumb-height, 2) 0;
    border-color: transparent;
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);

    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
    background: $ie-bottom-track-color;
  }

  &::-ms-fill-upper {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);

    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
    background: $track-color;
  }

  &::-ms-thumb {
    @include thumb;

    margin-top: math.div($track-height, 4);
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      background: lighten($track-color, $contrast);
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      background: lighten($track-color, $contrast);
    }
  }

  &:disabled {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb,
    &::-webkit-slider-runnable-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      cursor: not-allowed;
    }
  }
}
