@import 'styles/tokens/_colors';
@import 'styles/_media';
@import 'styles/_typography';
@import 'styles/tokens/_shadows';

.ChallengeInfo {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 14px 16px;
  width: 100%;
  background-color: #fff;

  @include media-up(breakpoint-sm) {
    padding: 20px 24px;
    min-height: 560px;
  }

  @include media-up(breakpoint-lg) {
    min-height: 504px;
  }
}

.ChallengeInfo_optinal {
  min-height: auto;
}

.ChallengeInfo__title {
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  @include media-up(breakpoint-lg) {
    padding-bottom: 24px;
  }
}

.ChallengeInfo__chartSubtitle {
  min-width: 75px;
}

.ChallengeInfo__section {
  display: flex;
  flex-direction: column;
  padding-bottom: 28px;

  @include media-up(breakpoint-sm) {
    flex: 1;
    padding-bottom: 0;
  }
}

.ChallengeInfo__section_optinal {
  @include media-up(breakpoint-lg) {
    flex: auto;
    flex-direction: row;
    justify-content: space-between;
  }
}

.ChallengeInfo__progress {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.ChallengeInfo__tagsSection {
  flex: 1;
  align-self: center;
  margin-left: 14px;

  @include media-up(breakpoint-sm) {
    margin-left: 10px;
  }

  @include media-up(breakpoint-lg) {
    margin-left: 20px;
  }
}

.ChallengeInfo__tagsTitle {
  margin-bottom: 8px;
}

.ChallengeInfo__tags {
  display: flex;
  flex-flow: row wrap;
}

.ChallengeInfo__tag {
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 6px;
  padding: 4px 12px;
}

.ChallengeInfo__tag_blue {
  color: $color-original-blue;
  background-color: $color-light-blue;
}

.ChallengeInfo__tag_green {
  color: $color-green;
  background-color: $color-light-green;
}

.ChallengeInfo__tag_violet {
  color: #7f0ed8;
  background-color: #ecd5ff;
}

.ChallengeInfo__circle {
  text {
    @include typography-h4;
  }
}

.ChallengeInfo__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  color: #fff;
  background-color: $color-original-blue;
}

.ChallengeInfo__helpIcon {
  margin-left: 8px;
  color: $color-grey;
  fill: $color-light-grey;
  cursor: pointer;

  &:hover {
    color: #fff;
    fill: $color-original-blue;
  }
}

.ChallengeInfo__tooltip {
  border-radius: 20px;
  padding: 12px 18px;
  max-width: 340px;
  box-shadow: $shadow-intense;
  color: $color-dark-grey;
  background: #fff;
}

.ChallengeInfo__timeSection {
  min-height: 114px;
}

.ChallengeInfo__timeSection_optinal {
  @include media-up(breakpoint-lg) {
    width: 342px;
  }
}

.ChallengeInfo__time {
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;

  &:first-of-type {
    margin-top: 0;
    margin-bottom: 16px;
  }

  @include media-up(breakpoint-sm) {
    margin-top: 10px;
  }
}

.ChallengeInfo__quantityTime {
  min-width: 54px;
  text-align: end;
}

.ChallengeInfo__chartTime_blue {
  background-color: $color-original-blue;
}

.ChallengeInfo__chartTime_green {
  background-color: $color-green;
}

.ChallengeInfo__chartTime_orange {
  background-color: $color-orange;
}

.ChallengeInfo__quantityTime_darkGrey {
  color: $color-dark-grey;
}

.ChallengeInfo__quantityTime_blue {
  color: $color-original-blue;
}

.ChallengeInfo__quantityTime_green {
  color: $color-green;
}

.ChallengeInfo__quantityTime_orange {
  color: $color-orange;
}

.ChallengeInfo__line {
  width: 100%;
}