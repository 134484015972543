@use 'sass:math';

@import 'styles/tokens/_colors';
@import 'styles/_typography';

$track-height-default: 4px;
$track-height-hovered: 6px;
$progress-color: $color-original-blue;
$track-color: rgba($color-light-grey, 0.5);
$buffered-color: rgba($color-light-grey, 0.8);
$seek-hover: rgba($color-light-grey, 0.8);

.ui-video-seek-slider {
  position: relative;
  width: 100%;
  touch-action: none;

  .track {
    overflow: hidden;
    outline: none;
    padding: 0;
    cursor: pointer;

    .main {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      outline: none;
      width: 100%;
      height: $track-height-hovered + 10px;
      box-sizing: border-box;

      &::before {
        content: '';
        position: absolute;
        overflow: hidden;
        outline: none;
        width: 100%;
        height: $track-height-default;
        background-color: $track-color;
        transition: height 0.1s;
      }

      .inner-seek-block {
        position: absolute;
        width: 100%;
        height: $track-height-default;
        transform-origin: 0 0;
        transition: height 0.1s, opacity 0.4s;
      }

      .buffered {
        z-index: 2;
        background-color: $buffered-color;
      }

      .seek-hover {
        z-index: 1;
        background-color: $seek-hover;
      }

      .connect {
        z-index: 3;
        background-color: $progress-color;
        transform-origin: 0 0;
      }

      &:focus {
        outline: none;
        border: 0;
      }

      &.with-gap {
        .inner-seek-block,
        &::before {
          margin: 0 auto;
          width: calc(100% - 2px);
        }
      }

      @media (hover) {
        &:hover {
          &::before {
            height: $track-height-hovered;
          }

          .inner-seek-block {
            height: $track-height-hovered;
          }
        }
      }
    }

    &:focus {
      outline: none;
      border: 0;
    }
  }

  .thumb {
    position: absolute;
    left: -6px;
    top: 3px;
    z-index: 4;
    width: 0;
    height: 0;
    pointer-events: none;

    .handler {
      border-radius: 100%;
      width: 100%;
      height: 100%;
      background-color: $progress-color;
      opacity: 0;
      transform: scale(0.4);
      transition: transform 0.2s, opacity 0.2s;
    }

    &.active {
      .handler {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .hover-time {
    @include typography-caption;

    position: absolute;
    left: 0;
    bottom: 5px;
    border-radius: 20px;
    padding: 10px 16px;
    width: 160px;
    text-align: center;
    color: $color-dark-grey;
    background-color: #fff;
    opacity: 0;
    pointer-events: none;

    div {
      @include typography-p3;

      color: #000;
    }

    .preview-screen {
      margin: 0 auto 10px;
      border-radius: 5px;
      width: 160px;
      height: 90px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #000;
    }

    &.active {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    .track {
      .main {
        .seek-hover {
          opacity: 1;
        }
      }
    }
  }
}
