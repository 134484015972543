@import 'styles/tokens/_colors';

.CandidateResumeVettedSkills__title {
  margin-bottom: 12px;
}

.CandidateResumeVettedSkills__title_margin {
  margin-bottom: 40px;
}

.CandidateResumeVettedSkills__description {
  margin-bottom: 40px;
  color: $color-dark-grey;
}

.CandidateResumeVettedSkills__icon {
  color: $color-grey;
  fill: $color-light-grey;
}

.CandidateResumeVettedSkills__card {
  & + & {
    margin-top: 40px;
  }
}

.CandidateResumeVettedSkills__modalList {
  margin: 10px 0;
  padding: 0 0 0 26px;

  li + li {
    margin-top: 4px;
  }
}