@import 'styles/tokens/_zIndex';
@import 'styles/tokens/_colors';

.ResultHeaderMobileMenu {
  position: fixed;
  left: 0;
  right: 0;
  top: 48px;
  bottom: 0;
  z-index: $zindex-modal;
  display: flex;
  background-color: $color-bg-grey-blue;
}