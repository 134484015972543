/* blue color */
$color-original-blue: #373af5;
$color-light-blue: #e5e5fe;
$color-dark-blue: #0c0e75;

/* bg color */
$color-bg: #f7f7fa;
$color-bg-white-blue: #eeeef2;
$color-bg-grey-blue: #262836;
$color-bg-deep-blue: #1a1927;

/* green color */
$color-green: #25bc86;
$color-light-green: #d3f2e7;

/* red color */
$color-red: #d94f4f;
$color-light-red: #f1d5d8;

/* orange color */
$color-orange: #f0a039;
$color-light-orange: #fff3e0;

/* color black */
$color-black: #1b1b1b;

/* color grey */
$color-grey: #d1d1d4;
$color-dark-grey: #6b6b6b;
$color-light-grey: #ebebeb;