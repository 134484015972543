@import 'styles/tokens/_colors';

.CandidateCardHeader {
  display: flex;
  margin-bottom: 16px;
}

.CandidateCardHeader__avatar {
  margin-right: 12px;
}

.CandidateCardHeader__name {
  margin-bottom: 2px;
}

.CandidateCardHeader__role {
  color: $color-dark-grey;
}

.CandidateCardHeader__blured {
  filter: blur(6px);
  user-select: none;
}