@import 'styles/tokens/_colors';
@import 'styles/_media';

.ResumeLoginBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;
  border-radius: 20px;
  padding: 16px;
  background: $color-bg;

  @include media-up(breakpoint-md) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 28px;
    padding: 36px;
  }

  @include media-up(breakpoint-xl) {
    margin-top: 40px;
    padding: 36px 52px;
  }
}

.ResumeLoginBanner__content {
  margin-right: 24px;
}

.ResumeLoginBanner__logoWrapper {
  margin-bottom: 18px;
  border-radius: 80px;
  padding: 8px 22px;
  width: fit-content;
  background-color: #fff;

  @include media-up(breakpoint-md) {
    margin-bottom: 24px;
    padding: 14px 32px;
  }
}

.ResumeLoginBanner__title {
  margin-bottom: 36px;
  max-width: 632px;
  color: $color-original-blue;
}

.ResumeLoginBanner__btns {
  display: flex;
  flex-direction: column;

  @include media-up(breakpoint-md) {
    flex-direction: row;
    align-items: center;
  }
}

.ResumeLoginBanner__btn {
  width: 100%;

  &:first-of-type {
    margin-bottom: 16px;
  }

  @include media-up(breakpoint-md) {
    width: auto;

    &:first-of-type {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
}

.ResumeLoginBanner__icon {
  margin-bottom: 20px;
  max-width: 200px;

  @include media-up(breakpoint-md) {
    margin-bottom: 0;
    min-width: 286px;
  }

  @include media-up(breakpoint-lg) {
    min-width: 316px;
  }
}