@import 'styles/tokens/_colors';
@import 'styles/_media';

.ProjectCard {
  position: relative;
  margin-left: 8px;
  padding-left: 24px;

  &:last-of-type {
    .ProjectCard__inner {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  & + & {
    margin-top: 20px;
  }

  @include media-up(breakpoint-md) {
    padding-left: 28px;
  }
}

.ProjectCard__inner {
  border-bottom: 1px solid $color-light-grey;
  padding-bottom: 20px;
}

/* Line */
.ProjectCard__circle {
  position: absolute;
  left: -7px;
  top: 4px;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: $color-dark-blue;
}

.ProjectCard__line {
  position: absolute;
  left: 0;
  top: 20px;
  width: 1px;
  height: 84%;
  background-color: $color-light-grey;
}

/* Text */
.ProjectCard__position {
  margin-bottom: 2px;
}

.ProjectCard__companyName {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  color: $color-dark-grey;
}

/* Date */
.ProjectCard__date {
  display: flex;
  align-items: center;
}

.ProjectCard__clockIcon {
  margin-right: 10px;
}

.ProjectCard_verticalLine {
  margin: 0 14px;
}

/* Description */
.ProjectCard__description {
  margin-top: 16px;
  white-space: pre-line;

  a {
    text-decoration: underline;
    color: $color-original-blue;
  }
}

/* Periods & Technologies */
.ProjectCard__subtitle {
  margin-top: 16px;
  margin-bottom: 8px;
}

.ProjectCard__section {
  display: flex;
  flex-wrap: wrap;
}

.ProjectCard__card {
  display: flex;
  align-items: center;
  margin-right: 26px;
}

.ProjectCard__cardIcon {
  margin-right: 6px;
  width: 22px;
  height: 22px;
}