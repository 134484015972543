@import 'styles/tokens/_colors';
@import 'styles/tokens/_transitions';
@import 'styles/_media';

.CardHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 22px 12px;

  @include media-up(breakpoint-lg) {
    flex-wrap: nowrap;
    padding: 22px 24px;
  }
}

.CardHeader__wrapper {
  &:hover {
    cursor: pointer;

    .CardHeader__iconWrapper {
      background-color: $color-original-blue;
    }

    .CardHeader__icon {
      color: #fff;
    }
  }
}

.CardHeader__badge {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  transform: translateY(-60%);
}

.CardHeader__title {
  display: flex;
  align-items: center;
  width: calc(100% - 44px);
  color: $color-original-blue;

  @include media-up(breakpoint-lg) {
    width: auto;
  }
}

.CardHeader__img {
  margin-right: 6px;
  width: 28px;
  height: 28px;
  min-width: 28px;
}

.CardHeader__starsWrapper {
  display: flex;
  align-items: center;
}

.CardHeader__level {
  display: flex;
  align-items: center;
  margin-top: 6px;
  border-radius: 20px;
  padding: 6px 14px;
  width: fit-content;
  background-color: #fff;

  @include media-up(breakpoint-lg) {
    margin-top: 0;
  }
}

.CardHeader__score {
  margin-right: 12px;
}

.CardHeader__iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  min-width: 32px;
  background-color: #fff;
  cursor: pointer;
}

.CardHeader__icon {
  transition: transform $transition-main;
}

.CardHeader__icon_rotated {
  transform: rotate(-180deg);
}

.CardHeader__topPercent {
  margin-top: 4px;
  border-radius: 20px;
  padding: 6px 18px;
  white-space: nowrap;
  color: $color-green;
  background-color: #fff;

  @include media-up(breakpoint-lg) {
    margin-top: 0;
    margin-right: 8px;
    margin-left: 12px;
  }
}

.CardHeader__level_violet {
  background-color: #7f0ed8;
}

.CardHeader__levelText_violet {
  color: #7f0ed8;
}

.CardHeader__level_blue {
  background-color: $color-original-blue;
}

.CardHeader__levelText_blue {
  color: $color-original-blue;
}

.CardHeader__level_green {
  background-color: $color-green;
}

.CardHeader__levelText_green {
  color: $color-green;
}
