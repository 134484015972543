@import 'styles/_media';
@import 'styles/tokens/_colors';

.CandidateResumeTechnologies__wrapper {
  border-radius: 20px;
  padding: 14px 16px;
  background: $color-bg;

  @include media-up(breakpoint-md) {
    padding: 20px 28px;
  }
}

.CandidateResumeTechnologies__infoWrapper {
  display: flex;
  flex-direction: column;

  @include media-up(breakpoint-xl) {
    flex-direction: row;
  }
}

.CandidateResumeTechnologies__info {
  & + & {
    margin-top: 16px;
  }

  @include media-up(breakpoint-xl) {
    & + & {
      margin-top: 0;
      margin-left: 20px;
    }
  }
}

.CandidateResumeTechnologies__titleMargin {
  margin-top: 20px;

  @include media-up(breakpoint-md) {
    margin-top: 28px;
  }
}

.CandidateResumeTechnologies__experiences {
  display: flex;
  flex-wrap: wrap;
}

.CandidateResumeTechnologies__experience {
  margin-top: 14px;
  margin-right: 14px;
}

.CandidateResumeTechnologies__description {
  color: $color-dark-grey;
}