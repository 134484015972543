@import 'styles/_media';
@import 'styles/_typography';
@import 'styles/tokens/_colors';
@import 'styles/tokens/_transitions';

.CandidateResumeVideo {
  display: flex;
  flex-direction: column;

  @include media-up(breakpoint-sm) {
    flex-direction: row;
  }
}

.CandidateResumeVideo__videoPreview {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  width: 100%;
  height: 160px;

  img {
    text-indent: -9999px;
    object-fit: contain;
    object-position: center;
  }

  @media screen and (min-width: 420px) {
    margin: 0 auto;
    width: 306px;
    height: 206px;
  }

  @media screen and (min-width: 576px) {
    margin: 0 26px 0 0;
  }
}

.CandidateResumeVideo__videoOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(29 11 102 / 60%);
}

.CandidateResumeVideo__btn {
  border-color: transparent;
  border-radius: 40px;
  padding: 8px 10px;
  font-family: $font-family-secondary;
  font-size: 16px;
  line-height: 24px;
}

.CandidateResumeVideo__modalBody {
  display: flex;
  flex-direction: column;

  @include media-up(breakpoint-md) {
    max-width: 1000px;
  }
}

.CandidateResumeVideo__modalContent {
  display: flex;
  flex-direction: column;
  padding: 16px 10px;

  @include media-up(breakpoint-md) {
    padding: 26px 30px;
  }
}

.CandidateResumeVideo__text {
  padding-top: 10px;

  @include media-up(breakpoint-md) {
    padding-top: 14px;
  }
}
