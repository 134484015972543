@import 'styles/tokens/_transitions';
@import 'styles/tokens/_colors';
@import 'styles/_media';

.SalesLandingHeader {
  width: 100%;
}

.SalesLandingHeader__inner {
  border-bottom: 1px solid $color-light-grey;
  box-shadow: none;

  @include media-up(breakpoint-lg) {
    padding: 0 100px;
  }
}

.SalesLandingHeader__logoWrapper {
  display: flex;
  column-gap: 10px;
  align-items: center;

  @include media-up(breakpoint-sm) {
    column-gap: 14px;
  }
}
