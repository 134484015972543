@import 'styles/tokens/_colors';
@import 'styles/_media';

.CandidateShareResultHeader {
  padding: 0 16px;
  background-color: $color-bg-deep-blue;

  @include media-up(breakpoint-lg) {
    padding: 0 24px;
  }
}

.CandidateShareResultHeader__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  height: 48px;
  max-width: 1240px;

  @include media-up(breakpoint-md) {
    height: 80px;
  }
}

.CandidateShareResultHeader__menu {
  @include media-up(breakpoint-md) {
    display: none;
  }
}

.CandidateShareResultHeader__menuBtn {
  background: #fff;

  &::after,
  &::before {
    background-color: #fff;
  }
}

.CandidateShareResultHeader__logo {
  order: 1;

  @include media-up(breakpoint-md) {
    order: unset;
  }
}

.CandidateShareResultHeader__btns {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  @include media-up(breakpoint-md) {
    flex-grow: unset;
    flex-direction: row;
    padding: 0;
  }
}

.CandidateShareResultHeader__btnSecondary {
  order: 1;
  margin-top: 22px;
  border-color: #fff;
  color: #fff;
  background-color: transparent;

  @include media-up(breakpoint-md) {
    order: unset;
    margin-top: 0;
    margin-right: 20px;
  }
}

.CandidateShareResultHeader__btn {
  width: 100%;

  @include media-up(breakpoint-md) {
    width: 160px;
  }
}