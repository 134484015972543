@import 'styles/tokens/_colors';
@import 'styles/tokens/_transitions';

.CandidateCardBadgesList__title {
  margin-bottom: 6px;
}

.CandidateCardBadgesList__title_marginMd {
  margin-top: 16px;
}

.CandidateCardBadgesList__title_marginSm {
  margin-top: 10px;
}

.CandidateCardBadgesList__badges {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
}

.CandidateCardBadgesList__badge {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  margin-left: 8px;
  border-radius: 8px;
  padding: 4px 8px;
  background-color: $color-bg;
  transition: background-color $transition-main;
}

.CandidateCardBadgesList__badge_active {
  background-color: $color-light-blue;
}

.CandidateCardBadgesList__badgeImg {
  margin-right: 6px;
  width: 20px;
  height: 20px;
}