@import 'styles/tokens/_colors';
@import 'styles/media';

.CandidateResumeOtherExperience__experiences {
  display: flex;
  flex-wrap: wrap;
}

.CandidateResumeOtherExperience__experience {
  display: flex;
  align-items: center;
  margin-top: 14px;
  margin-right: 26px;
}

.CandidateResumeOtherExperience__description {
  color: $color-dark-grey;
}

.CandidateResumeOtherExperience__icon {
  margin-right: 8px;
  border-radius: 8px;
  width: 22px;
  height: 22px;

  @include media-up(breakpoint-md) {
    width: 26px;
    height: 26px;
  }
}