@import 'styles/tokens/_colors';
@import 'styles/_media';

.CandidateShareResultPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $color-bg;
}

.CandidateShareResultPage__content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 16px;

  @include media-up(breakpoint-lg) {
    padding: 0 24px;
  }
}

.CandidateShareResultPage__loading {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  color: $color-original-blue;
}