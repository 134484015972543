@import 'styles/tokens/_colors';

.Avatar {
  overflow: hidden;
  border-radius: 50%;
}

.Avatar_default {
  background-color: $color-bg;
}
