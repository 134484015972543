@import 'styles/tokens/_colors';
@import 'styles/tokens/_transitions';

.BurgerMenu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  cursor: pointer;
}

.BurgerMenu_opened {
  .BurgerMenu__btn {
    background: transparent;
    transform: translateX(-50px);

    &::before {
      background: $color-original-blue;
      transform: rotate(45deg) translate(35px, -35px);
    }

    &::after {
      background: $color-original-blue;
      transform: rotate(-45deg) translate(35px, 35px);
    }
  }
}

.BurgerMenu__btn {
  border-radius: 8px;
  width: 38px;
  height: 2px;
  background: $color-black;
  transition: transform $transition-main;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 8px;
    width: 38px;
    height: 2px;
    background: $color-black;
    transition: transform $transition-main;
  }

  &::before {
    transform: translateY(-10px);
  }

  &::after {
    transform: translateY(10px);
  }
}
