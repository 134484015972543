@import 'styles/tokens/_colors';
@import 'styles/_media';

.ResumeLoginBanner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  border-radius: 20px;
  background-color: $color-bg;
}

.ResumeLoginBanner__content {
  flex-grow: 1;
  padding: 20px 16px;

  @include media-up(breakpoint-md) {
    flex-grow: 0;
    padding: 26px 0 26px 32px;
  }
}

.ResumeLoginBanner__description {
  margin-top: 10px;
  margin-bottom: 16px;
  color: $color-dark-grey;
}

.ResumeLoginBanner__btn {
  width: 100%;

  @include media-up(breakpoint-md) {
    width: 140px;
  }
}

.ResumeLoginBanner__icon {
  display: none;

  @include media-up(breakpoint-md) {
    display: block;
    margin-right: 32px;
    margin-left: 16px;
  }

  @include media-up(breakpoint-lg) {
    margin-right: 70px;
  }
}