@import 'styles/tokens/_colors';
@import 'styles/_media';

.LevelScore {
  display: flex;
  column-gap: 2px;
  align-items: center;
  margin-right: 10px;
}

.LevelScore__block {
  border-radius: 4px;
  width: 14px;
  height: 14px;

  @include media-up(breakpoint-sm) {
    width: 25px;
  }
}

.LevelScore_default {
  background-color: $color-light-grey;
}
