@import 'styles/tokens/_colors';
@import 'styles/_media';

.ResumeContacts {
  height: 64px;

  @include media-up(breakpoint-md) {
    height: 72px;
  }

  @include media-up(breakpoint-lg) {
    height: 64px;
  }

  @include media-up(breakpoint-xl) {
    height: 40px;
  }
}

.ResumeContacts__inner {
  position: absolute;
  left: 16px;
  right: 16px;
  top: 0;
  margin: 0;
  border-radius: 0 0 20px 20px;
  padding: 14px 24px;
  background: $color-bg;

  @include media-up(breakpoint-md) {
    left: auto;
    right: 32px;
  }
}

.ResumeContacts__item {
  display: flex;
  align-items: center;
  color: $color-original-blue;

  a:hover {
    text-decoration: underline;
  }

  & + & {
    margin-top: 8px;
  }
}

.ResumeContacts__icon {
  margin-top: -2px;
  margin-right: 8px;
}