@import 'styles/tokens/_colors';
@import 'styles/tokens/_shadows';
@import 'styles/_media';

.TechnologyTitle {
  display: flex;
  align-items: center;
  width: fit-content;
}

.TechnologyTitle__icon {
  margin-left: 8px;
  color: $color-grey;
  fill: $color-light-grey;
}
