@import 'styles/tokens/_colors';
@import 'styles/_media';
@import 'styles/_mixins';

.CandidateResumeInfoFull {
  display: flex;
  border-bottom: 1px solid $color-light-grey;
  padding-bottom: 44px;

  @include media-up(breakpoint-md) {
    padding-bottom: 32px;
  }
}

.CandidateResumeInfoFull__avatar {
  overflow: hidden;
  margin-right: 10px;
  border-radius: 20px;
  width: 60px;
  height: 60px;
  min-width: 60px;
  background-color: $color-bg;

  @include media-up(breakpoint-md) {
    margin-right: 26px;
    width: 194px;
    height: 194px;
    min-width: 194px;

    svg {
      width: 60px;
      height: 60px;
    }
  }

  @include media-up(breakpoint-xl) {
    width: 230px;
    height: 230px;
    min-width: 230px;
  }
}

.CandidateResumeInfoFull__content {
  width: 100%;
}

.CandidateResumeInfoFull__header {
  display: flex;
  align-items: center;
}

.CandidateResumeInfoFull__headerInner {
  flex-grow: 1;
}

.CandidateResumeInfoFull__titleWrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}

.CandidateResumeInfoFull__title {
  margin-bottom: 2px;
}

.CandidateResumeInfoFull__list {
  @include clear-unordered-list;
}

.CandidateResumeInfoFull__listItem {
  display: flex;
  align-items: center;

  &:first-of-type {
    margin-top: 16px;
  }

  & + & {
    margin-top: 10px;
  }
}

.CandidateResumeInfoFull__icon {
  margin-top: -2px;
  margin-right: 10px;
}

.CandidateResumeInfoFull__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 28px;

  @include media-up(breakpoint-md) {
    flex-direction: row;
    margin-top: 0;
    margin-left: 32px;
  }
}

.CandidateResumeInfoFull__example {
  border-radius: 100px;
  padding: 8px 24px;
  color: #fff;
  background-color: $color-original-blue;
}

.CandidateResumeInfoFull__link {
  margin-left: 5px;

  &:hover {
    color: $color-original-blue;
  }
}