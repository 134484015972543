@import 'styles/_typography';

.AvatarWithName {
  display: flex;
  align-items: center;
}

.AvatarWithName__avatarMargin {
  margin-right: 10px;
}

.AvatarWithName__firstLine {
  @include typography-p1;
}

.AvatarWithName__secondLine {
  @include typography-p1;

  margin-top: 2px;
}