@import 'styles/tokens/_colors';
@import 'styles/tokens/_transitions';
@import 'styles/tokens/_shadows';
@import 'styles/_media';

.AvatarWithLogout__logout {
  display: none;

  @include media-up(breakpoint-xl) {
    display: flex;
    align-items: center;
  }
}

.AvatarWithLogout__icon {
  margin-left: 16px;
  transition: transform $transition-main;
  cursor: pointer;
}

.AvatarWithLogout__icon_active {
  transform: rotate(-180deg);
}

.AvatarWithLogout__tooltip {
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 16px 20px;
  width: 180px;
  box-shadow: $shadow-basic;
  color: $color-black;
  background: #fff;
  transition: color $transition-main;
  cursor: pointer;

  &:hover {
    color: $color-original-blue;
  }

  &:active {
    color: $color-dark-blue;
  }
}

.AvatarWithLogout__tooltipIcon {
  margin-right: 14px;
}