.CandidateShareContent__title {
  margin-top: 38px;
  margin-bottom: 18px;
}

.CandidateShareContent__inner {
  margin: 0 auto 60px;
  width: 100%;
  max-width: 860px;
}

.CandidateShareContent__vettedSkillCard {
  background-color: #fff;
}
