@import 'styles/tokens/_transitions';
@import 'styles/tokens/_zIndex';
@import 'styles/tokens/_shadows';
@import 'styles/_media';

.HeaderContainer {
  height: 48px;
  background-color: #fff;

  @include media-up(breakpoint-md) {
    height: 80px;
  }
}

.HeaderContainer__inner {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: calc($zindex-modal-backdrop - 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 48px;
  box-shadow: rgb(50 50 93 / 25%) 0 30px 60px -12px, rgb(0 0 0 / 30%) 0 18px 36px -18px;
  background-color: #fff;

  @include media-up(breakpoint-md) {
    padding: 0 24px;
    height: 80px;
  }

  @include media-up(breakpoint-lg) {
    position: static;
    box-shadow: none;
  }

  @include media-up(breakpoint-xl) {
    padding-right: 40px;
  }
}