@import 'styles/tokens/_colors';
@import 'styles/_media';

.EducationCard {
  position: relative;
  margin-left: 8px;
  padding-left: 24px;

  &:last-of-type {
    .EducationCard__inner {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  @include media-up(breakpoint-md) {
    padding-left: 28px;
  }
}

.EducationCard__inner {
  border-bottom: 1px solid $color-light-grey;
  padding-bottom: 28px;
}

.EducationCard__circle {
  position: absolute;
  left: -7px;
  top: 4px;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: $color-green;
}

.EducationCard__line {
  position: absolute;
  left: 0;
  top: 20px;
  width: 1px;
  height: 60%;
  background-color: $color-light-grey;
}

.EducationCard__title {
  margin-bottom: 2px;
}

.EducationCard__description {
  margin-bottom: 16px;
  color: $color-dark-grey;
}

.EducationCard__period {
  display: flex;
}

.EducationCard__icon {
  margin-top: -4px;
  margin-right: 4px;

  @include media-up(breakpoint-md) {
    margin-top: -2px;
    margin-right: 10px;
  }
}

.EducationCard__verticalLine {
  padding: 0 14px;
}