@import 'styles/tokens/_colors';

@mixin common-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  color: $color-original-blue;
  transform: translate(-50%, -50%);
}

.ImgPreview {
  position: relative;
}

.ImgPreview__spinner {
  @include common-icon;
}

.ImgPreview__img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  visibility: hidden;
}

.ImgPreview__img_loaded {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s;
}

.ImgPreview__placeholder {
  @include common-icon;

  svg {
    width: 100%;
    height: 100%;
  }
}