@import 'styles/tokens/_transitions';
@import 'styles/tokens/_colors';
@import 'styles/_media';

.CardContent {
  overflow: hidden;
  transition: max-height $transition-main;
}

.CardContent__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 12px 22px;
  row-gap: 14px;

  @include media-up(breakpoint-sm) {
    column-gap: 16px;
    flex-direction: row;
  }

  @include media-up(breakpoint-lg) {
    column-gap: 32px;
    padding: 0 24px 22px;
  }
}

.CardContent__step {
  width: 100%;
}

.CardContent__title {
  margin-bottom: 6px;

  @include media-up(breakpoint-lg) {
    margin-bottom: 28px;
  }
}

.CardContent__button {
  margin-top: 32px;
  width: 100%;
}

.CardContent__button_optinal {
  align-self: center;
  width: 260px;
}

.CardContent__results {
  @include media-up(breakpoint-lg) {
    flex-grow: 1;
    margin-left: 36px;
    max-width: 452px;
  }
}

.CardContent__questionsCount {
  margin-bottom: 14px;
  color: $color-dark-grey;

  @include media-up(breakpoint-lg) {
    margin-bottom: 12px;
  }
}

.CardContent__tag {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 6px;
  }

  @include media-up(breakpoint-lg) {
    & + & {
      margin-top: 10px;
    }
  }
}

.CardContent__tagName {
  margin-right: 16px;
}

.CardContent__tagScore {
  display: flex;
  align-items: center;
}

.CardContent__starsScore {
  margin-top: -2px;
  margin-left: 18px;
}