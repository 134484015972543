@import 'styles/tokens/_colors';
@import 'styles/_media';

.NotFoundLayout {
  min-height: 100vh;
  background-color: $color-bg;
}

.NotFoundLayout__content {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.NotFoundLayout__warning {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 74px 16px;
  border-radius: 20px;
  padding: 20px;
  max-width: 600px;
  text-align: center;
  background-color: #fff;

  @include media-up(breakpoint-sm) {
    margin: 148px 0;
    padding: 40px;
  }
}

.NotFoundLayout__title {
  margin: 22px 0;

  @include media-up(breakpoint-sm) {
    margin: 38px 0;
  }
}

.NotFoundLayout__btn {
  width: 212px;
}