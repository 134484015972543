@import 'styles/tokens/_colors';
@import 'styles/_media';

.InterviewerCard {
  margin-top: 16px;

  @include media-up(breakpoint-sm) {
    margin-top: 0;
  }
}

.InterviewerCard__header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.InterviewerCard__img {
  margin-right: 10px;
}

.InterviewerCard__role {
  color: $color-dark-grey;
}

.InterviewerCard__badge {
  margin-bottom: 10px;
  border-radius: 20px;
  padding: 2px 6px;
  width: fit-content;
  color: $color-dark-grey;
  background: $color-light-grey;
}

.InterviewerCard__topicsTitle {
  margin-bottom: 6px;
}

.InterviewerCard__list {
  margin: 0;
  padding: 0 0 0 24px;
}