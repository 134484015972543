@import 'styles/_media';

.ResumeContainer {
  background-color: rgb(2 8 64 / 55%);
}

.ResumeContainer__container {
  position: relative;
  margin: 0 auto;
  padding: 20px 16px;
  min-height: 100vh;
  max-width: 1180px;
  background-color: #fff;

  @include media-up(breakpoint-xl) {
    padding: 44px 32px;
  }
}