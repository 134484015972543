@import 'styles/_media';
@import 'styles/_typography';
@import 'styles/tokens/_colors';

.SalesLandingContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  padding: 0 16px 26px;
  min-height: 100vh;
  background-image: url('./perspectiveGrid.svg');
  background-position-x: center;
  background-size: cover;
  background-repeat: no-repeat;

  @include media-up(breakpoint-md) {
    margin-top: 42px;
    padding: 0 24px 42px;
  }

  @include media-up(breakpoint-lg) {
    padding: 0 100px 42px;
  }
}

.SalesLandingContent__title {
  align-self: flex-start;
  font-family: $font-family-secondary, $font-family-default;
  font-weight: 400;
  font-size: 26px;
  line-height: 36px;
  color: $color-dark-blue;

  @include media-up(breakpoint-md) {
    font-size: 52px;
    line-height: 72px;
  }
}

.SalesLandingContent__titleTechnology {
  color: $color-original-blue;
}

.SalesLandingContent__candidatesList {
  display: grid;
  column-gap: 28px;
  grid-auto-columns: auto;
  padding: 28px 0;
  width: 100%;
  row-gap: 28px;

  @include media-up(breakpoint-sm) {
    padding: 48px 0;
  }

  @include media-up(breakpoint-md) {
    grid-template-columns: repeat(2, minmax(330px, 1fr));
  }

  @include media-up(breakpoint-lg) {
    padding: 74px 0;
  }

  @include media-up(breakpoint-xl) {
    column-gap: 44px;
    grid-template-columns: repeat(2, minmax(330px, 1fr));
    row-gap: 32px;
  }

  @include media-up(breakpoint-xxl) {
    justify-content: center;
    grid-template-columns: repeat(3, minmax(330px, 430px));
  }
}

.SalesLandingContent__banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 0;
  width: 100%;
  background-color: #fff;

  @include media-up(breakpoint-sm) {
    padding: 10px 12px;
  }
}

.SalesLandingContent__bannerList {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  row-gap: 10px;

  @include media-up(breakpoint-sm) {
    padding: 16px 20px;
  }
}

.SalesLandingContent__bannerImg {
  display: none;

  @include media-up(breakpoint-md) {
    display: block;
    width: 354px;
    height: 208px;
  }
}

.SalesLandingContent__bannerListItem {
  color: $color-dark-grey;
}

.SalesLandingContent__bannerListIcon {
  margin-right: 10px;
}