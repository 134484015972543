@import 'styles/_mixins';

.CandidateCardInfo__list {
  @include clear-unordered-list;
}

.CandidateCardInfo__listItem {
  display: flex;
  align-items: center;

  & + & {
    margin-top: 10px;
  }
}

.CandidateCardInfo__listItemIcon {
  margin-top: -4px;
  margin-right: 6px;
}

.CandidateCardInfo__summary {
  @include text-overflow(4);

  margin-top: 16px;
}