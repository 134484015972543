@import 'styles/tokens/colors';
@import 'styles/tokens/transitions';
@import 'styles/media';

.TechnologyInfo__title {
  margin-top: 6px;
  min-width: 100%;
  color: $color-dark-grey;
}

.TechnologyInfo__content {
  display: flex;
  flex-wrap: wrap;
}

.TechnologyInfo__card {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  border: 1px solid $color-light-grey;
  border-radius: 12px;
  padding: 8px 12px;
  background-color: #fff;
  transition: background-color $transition-main, color $transition-main;
}

.TechnologyInfo__card_selected {
  background-color: $color-light-blue;
}

.TechnologyInfo__card_animation {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: -150%;
    top: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(90deg, rgba(#fff, 0.3), rgba(#fff, 1), rgba(#fff, 0.3));
    transform: skewX(-30deg);
    animation: flare 2s ease-in-out;

    @keyframes flare {
      0% {
        left: -150%;
      }

      100% {
        left: 150%;
      }
    }
  }

  &:hover {
    min-width: 44px;
    color: #fff;
    background-color: $color-original-blue;

    .TechnologyInfo__experience {
      color: #fff;
    }
  }
}

.TechnologyInfo__experience {
  margin-left: 8px;
  min-width: 44px;
  text-align: right;
  color: $color-dark-grey;
  transition: color $transition-main;
}

.TechnologyInfo__link {
  color: $color-original-blue;

  &:hover {
    cursor: pointer;
  }
}

.TechnologyInfo__img {
  margin-right: 8px;
  border-radius: 8px;
  width: 22px;
  height: 22px;

  @include media-up(breakpoint-md) {
    width: 26px;
    height: 26px;
  }
}

.TechnologyInfo__icn {
  margin-left: 8px;
  width: 22px;
  height: 22px;
  color: $color-green;

  @include media-up(breakpoint-md) {
    width: 26px;
    height: 26px;
  }
}

.TechnologyInfo__name {
  min-width: 104px;
}
