@import 'styles/tokens/_colors';
@import 'styles/_media';

.ResumeHireBanner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 18px;
  border-radius: 20px;
  background: $color-bg;

  @include media-up(breakpoint-md) {
    margin-bottom: 24px;
  }

  @include media-up(breakpoint-lg) {
    margin-bottom: 28px;
  }
}

.ResumeHireBanner__info {
  flex-grow: 1;
  padding: 20px 16px;

  @include media-up(breakpoint-md) {
    flex-grow: unset;
    padding: 26px 32px;
  }
}

.ResumeHireBanner__title {
  margin-bottom: 8px;
}

.ResumeHireBanner__logo {
  margin-bottom: 18px;
  border-radius: 64px;
  padding: 10px 26px;
  width: fit-content;
  background-color: #fff;
}

.ResumeHireBanner__description {
  margin-bottom: 18px;
  color: $color-dark-grey;
}

.ResumeHireBanner__btn {
  width: 100%;

  @include media-up(breakpoint-md) {
    width: auto;
  }
}

.ResumeHireBanner__img {
  display: none;

  @include media-up(breakpoint-md) {
    display: block;
    margin-right: -62px;
    width: 354px;
    height: 208px;
  }

  @include media-up(breakpoint-lg) {
    margin-right: 0;
  }
}