@import 'styles/tokens/_colors';
@import 'styles/_media';

.CandidateResumeSection {
  display: flex;
  flex-direction: column;
  margin-top: 28px;

  @include media-up(breakpoint-md) {
    flex-direction: row;
  }
}

.CandidateResumeSection__title {
  display: flex;
  margin-bottom: 16px;

  @include media-up(breakpoint-md) {
    margin-right: 26px;
    width: 194px;
    min-width: 194px;
  }

  @include media-up(breakpoint-xl) {
    width: 230px;
    min-width: 230px;
  }
}

.CandidateResumeSection__icon {
  margin-top: -2px;
  margin-right: 12px;
}

.CandidateResumeSection__content {
  flex-grow: 1;
  padding-bottom: 22px;

  @include media-up(breakpoint-md) {
    padding-bottom: 28px;
  }
}

.CandidateResumeSection__border {
  border-bottom: 1px solid $color-light-grey;
}

.CandidateResumeSection__content_borderHidden {
  border-bottom: none;
}