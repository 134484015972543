@import 'styles/_media';

.NotFoundHeader {
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 48px;
  background-color: #fff;

  @include media-up(breakpoint-md) {
    height: 80px;
  }
}